import React from 'react';
import Layout from '../components/layout';
import { withForceUpdate } from '../i18n/with-force-update';

const PrivacyPage = ({ t, pageContext: { language } }) => {
    return (
        <Layout title={t('Privacy notice')} showTitle lang={language}>
            <i>Still under construction, come back later.</i>
        </Layout>
    );
};

export default withForceUpdate(PrivacyPage);
